<template>
    <v-container id="privacy">
        <v-row>
            <v-col cols="10">
                <h1 class="text-h2">Privacy Policy</h1>

                <h5 class="text-h6">Last updated on 28/10/2022</h5>

                <h3>Your privacy is important to us</h3>

                <p>
                    {{ constants.app_name }} is a service offered by
                    <a href="https://www.smartnetworkingsolutions.uk">SMART NETWORKING SOLUTIONS LTD</a>.
                </p>

                <p>SMART NETWORKING SOLUTIONS LTD is a private company registered in the United Kingdom, located at:</p>

                <ul class="address">
                    <li><strong>Smart Networking Solutions Ltd</strong></li>
                    <li>27 Old Gloucester Street</li>
                    <li>London WC1N 3AX</li>
                    <li>United Kingdom</li>
                </ul>

                <p></p>

                <p>
                    It is SMART NETWORKING SOLUTIONS LTD's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to <a :href="`https://${constants.web_url}`">{{ constants.web_url }}</a> and <a :href="`https://${constants.app_url}`">{{ constants.app_url }}</a> (hereinafter, "us", "we", or "{{ constants.app_name }}"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
                </p>
                <p>
                    This Privacy Policy, together with the Terms of service posted on our Website, set forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms of service.
                </p>

                <h2 id="tableofcontents">Contents</h2>

                <h2>1. Website Visitors</h2>
                <p>
                    Like most website operators, SMART NETWORKING SOLUTIONS LTD collects non-personally-identifying
                    information of the sort that web browsers and servers typically make available, such as the browser
                    type, language preference, referring site, and the date and time of each visitor request. SMART
                    NETWORKING SOLUTIONS LTD's purpose in collecting non-personally identifying information is to better
                    understand how SMART NETWORKING SOLUTIONS LTD's visitors use its website. From time to time, SMART
                    NETWORKING SOLUTIONS LTD may release non-personally-identifying information in the aggregate, e.g.,
                    by publishing a report on trends in the usage of its website.
                </p>
                <p>
                    SMART NETWORKING SOLUTIONS LTD also collects potentially personally-identifying information like
                    Internet Protocol (IP) addresses for logged in users.
                </p>

                <h2>2. Security</h2>
                <p>
                    The security of your Personal Information is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage is 100% secure. While we strive to
                    use commercially acceptable means to protect your Personal Information, we cannot guarantee its
                    absolute security.
                </p>

                <h2>3. Aggregated Statistics</h2>
                <p>
                    SMART NETWORKING SOLUTIONS LTD may collect statistics about the behaviour of visitors to its
                    website. SMART NETWORKING SOLUTIONS LTD may display this information publicly or provide it to
                    others. However, SMART NETWORKING SOLUTIONS LTD does not disclose your personally-identifying
                    information.
                </p>

                <h2>4. Cookies</h2>
                <p>
                    To enrich and perfect your online experience, SMART NETWORKING SOLUTIONS LTD uses "Cookies", similar
                    technologies and services provided by others to display personalised content, appropriate
                    advertising and store your preferences on your computer.
                </p>
                <p>
                    A cookie is a string of information that a website stores on a visitor's computer, and that the
                    visitor's browser provides to the website each time the visitor returns. SMART NETWORKING SOLUTIONS
                    LTD uses cookies to help SMART NETWORKING SOLUTIONS LTD identify and track visitors, their usage of
                    <a :href="`https://${constants.web_url}`">{{ constants.web_url }}</a> and
                    <a :href="`https://${constants.app_url}`">{{ constants.app_url }}</a>, and their website access
                    preferences. SMART NETWORKING SOLUTIONS LTD visitors who do not wish to have cookies placed on their
                    computers should set their browsers to refuse cookies before using SMART NETWORKING SOLUTIONS LTD's
                    websites, with the drawback that certain features of SMART NETWORKING SOLUTIONS LTD's websites may
                    not function properly without the aid of cookies.
                </p>
                <p>
                    By continuing to navigate our website without changing your cookie settings, you hereby acknowledge
                    and agree to SMART NETWORKING SOLUTIONS LTD's use of cookies.
                </p>

                <h2>5. Subprocessors</h2>
                <p>
                    We use a select number of trusted external service providers for certain technical data processing
                    and/or service offerings. These service providers are carefully selected and meet high data
                    protection and security standards. We only share information with them that is required for the
                    services offered and we contractually bind them to keep any information we share with them as
                    confidential and to process Personal Data only according to our instructions.
                </p>

                <p>
                    {{ constants.app_name }} uses the following subprocessors to process the data collected by our
                    websites:
                </p>

                <p>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Subprocessor</th>
                                    <th class="text-left">Data location</th>
                                    <th class="text-left">Service</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>First Root UG</td>
                                    <td>Germany</td>
                                    <td>Third-party hosting provider for servers</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </p>

                <h2>6. Third party services we use</h2>
                <p>
                    When you visit our websites, or purchase products or services, we use the following third party
                    services which may collect personal data:
                </p>

                <p>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Recipient</th>
                                    <th class="text-left">Purpose of processing</th>
                                    <th class="text-left">Lawful basis</th>
                                    <th class="text-left">Data location and security</th>
                                    <th class="text-left">Personal data collected by the third party</th>
                                    <th class="text-left">Privacy policy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>SendGrid</td>
                                    <td>To send you newsletter emails after you signup</td>
                                    <td>Explicit consent</td>
                                    <td>USA</td>
                                    <td>Email</td>
                                    <td><a href="https://www.twilio.com/legal/privacy">link</a></td>
                                </tr>
                                <tr>
                                    <td>SendGrid</td>
                                    <td>To send you emails as a part of using the services</td>
                                    <td>Contract</td>
                                    <td>USA</td>
                                    <td>Email</td>
                                    <td><a href="https://www.twilio.com/legal/privacy">link</a></td>
                                </tr>
                                <tr>
                                    <td>Stripe</td>
                                    <td>To process payments and manage subscriptions</td>
                                    <td>Contract</td>
                                    <td>worldwide</td>
                                    <td>Email</td>
                                    <td><a href="https://stripe.com/en-cz/privacy">link</a></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </p>

                <p>
                    We also collect and maintain aggregated, anonymized or pseudonymized information which we may retain
                    indefinitely to protect the safety and security of our Site, improve our Services or comply with
                    legal obligations.
                </p>

                <h2>7. Privacy Policy Changes</h2>
                <p>
                    Although most changes are likely to be minor, SMART NETWORKING SOLUTIONS LTD may change its Privacy
                    Policy from time to time, and in SMART NETWORKING SOLUTIONS LTD's sole discretion. SMART NETWORKING
                    SOLUTIONS LTD encourages visitors to frequently check this page for any changes to its Privacy
                    Policy. Your continued use of this site after any change in this Privacy Policy will constitute your
                    acceptance of such change.
                </p>

                <h2>8. Contact Information &amp; Credit</h2>
                <p>
                    This privacy policy was created at <a style="color:inherit;text-decoration:none;" href="https://privacyterms.io/en/privacy-policy-generator/" title="Privacy policy generator" target="_blank">privacyterms.io privacy policy generator</a> with parts inspired by <a href="https://matomo.org/privacy-policy/">Privacy Policy kindly provided by Matomo</a> (published under license <a href="https://creativecommons.org/licenses/by/4.0/">CC BY</a>). If you have any questions about our Privacy Policy, please contact us via <a :href="`mailto:privacy@${constants.domain}.uk`">email</a>.
                </p>

                <p>
                    If you wish to communicate directly with our data protection officer (because you have a
                    particularly sensitive matter for example), please contact them by post, as communication by e-mail
                    could always have security gaps. Please direct your request to the following address:
                </p>

                <ul class="address">
                    <li>{{ constants.app_name }}</li>
                    <li><strong>Smart Networking Solutions Ltd</strong></li>
                    <li>27 Old Gloucester Street</li>
                    <li>London WC1N 3AX</li>
                    <li>United Kingdom</li>
                </ul>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useConstants } from "@/const/const.js";
const constants = useConstants();

export default {
    name: "PrivacyView",
    components: {},
    setup() {
        return {
            constants,
        };
    },
    metaInfo() {
        return {
            title: constants.app_name,
            titleTemplate: "%s | Privacy Policy",
            link: [
                {rel: "canonical", href: `https://${constants.web_url}/privacy`}
            ]
        };
    },
};
</script>
